import { Injectable } from '@angular/core';

import { CollaboratorType } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorTypesService extends GenericObjectsService<CollaboratorType> {
  protected baseUrl = '/collaborator-types';
  cachedCollaboratorTypes?: Partial<CollaboratorType>[];

  async getCachedCollaboratorTypes(forceClearCache?: boolean) {
    if (this.cachedCollaboratorTypes && !forceClearCache) {
      return this.cachedCollaboratorTypes;
    }

    const collaboratorTypes = await this.list({
      order: ['name'],
    });

    this.cachedCollaboratorTypes = collaboratorTypes.data;

    return this.cachedCollaboratorTypes;
  }
}
